import React, { useState, useEffect, useCallback } from 'react';
import VisualizationsHeader from './components/VisualizationsHeader';
import Header from './components/ContentHeader';
import VisualizationsChart from './components/VisualizationsChart';
import Photovoltaic from './components/Photovoltaic';
import EnergyStore from './components/EnergyStore';
import ChargeInfo from './components/ChargeInfo';
import GreenEnergy from './components/GreenEnergy';
import RScaleScreen from 'r-scale-screen';
import 'dayjs/locale/zh-cn';
import dayjs from 'dayjs';

import styles from './index.module.scss';

interface Props {}
const Visualizations: React.FC<Props> = () => {
  const [days, setDays] = useState<number>(0);
  // 实时功率
  const [power, setPower] = useState<number>(0);
  // 今日发电量
  const [todayPower, setTodayPower] = useState<number>(0);
  // 本月发电量
  const [monthPower, setMonthPower] = useState<number>(0);
  // 储能站发电量
  const [energyStorePower, setEnergyStorePower] = useState<number>(0);
  // 储能站放电量
  const [energyStoreDischarge, setEnergyStoreDischarge] = useState<number>(0);

  const random = useCallback((min: number, max: number) => {
    const randomNumber = Math.random() * (max - min) + min;
    return Number(randomNumber.toFixed(2));

  }, []);

  const updateEnergyStorePower = useCallback((number: number) => {
    setEnergyStorePower(number);
  }, []);

  const updateEnergyStoreDischarge = useCallback((number: number) => {
    setEnergyStoreDischarge(number);
  }, []);

  const updatePower = useCallback((number: number) => {
    setPower(number);
  }, []);

  const updateTodayPower = useCallback((number: number) => {
    setTodayPower(number);
  }, []);

  const updateMonthPower = useCallback((number: number) => {
    setMonthPower(number);
  }, []);

  useEffect(() => {
    const now = dayjs(); // 获取现在的日期时间
    const startDay = dayjs('2023-06-20'); // 设置安全日期
    setDays(now.diff(startDay, 'day')); // 设置安全运行天数
  }, []);

  return (
    <RScaleScreen height={1080} width={1920} delay={3}>
      <div className={styles.screen_wrapper} id="screen_wrapper">
        <main className={styles.container} id="screen">
          <VisualizationsHeader></VisualizationsHeader>

          <div className={styles.content}>
            <div className={styles.content_left}>
              <div className={styles.content_left_build}>
                <Header title="建设规模"></Header>
                <div className={styles.content_left_build_content}>
                  <div></div>
                  <div>光伏站装机容量 (kWp)</div>
                  <div>381.84</div>
                  <div>充电站装机容量 (kW)</div>
                  <div>190.00</div>
                  <div>储能站装机容量 (kW/kWh)</div>
                  <div>100.00/215.00</div>
                </div>

                <div className={styles.content_left_bottom}></div>
              </div>

              <Photovoltaic
                power={power}
                todayPower={todayPower}
                monthPower={monthPower}
                days={days}
                random={random}
                updatePower={updatePower}
                updateTodayPower={updateTodayPower}
                updateMonthPower={updateMonthPower}
              ></Photovoltaic>
              <EnergyStore
                days={days}
                energyStorePower={energyStorePower}
                energyStoreDischarge={energyStoreDischarge}
                updateEnergyStorePower={updateEnergyStorePower}
                updateEnergyStoreDischarge={updateEnergyStoreDischarge}
              ></EnergyStore>
            </div>

            <div className={styles.content_center}>
              <div className={styles.chargeLocation}>
                <div></div>
                <div>光伏站</div>
              </div>

              <div className={styles.chargeStation}>
                <div></div>
                <div>充电站</div>
              </div>
              <div className={styles.photovoltaicstation}>
                <div></div>
                <div>储能站</div>
              </div>
            </div>

            <div className={styles.content_right}>
              <ChargeInfo days={days} random={random}></ChargeInfo>
              <GreenEnergy todayPower={todayPower} monthPower={monthPower} random={random}></GreenEnergy>
              <div className={styles.content_right_electric}>
                <Header title="近七日电量数据"></Header>
                <div className={styles.content_right_electric_content}>
                  <VisualizationsChart
                    todayPower={todayPower}
                    random={random}
                    energyStorePower={energyStorePower}
                    energyStoreDischarge={energyStoreDischarge}
                  ></VisualizationsChart>
                </div>
                <div className={styles.content_right_electric_bottomLine}></div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </RScaleScreen>
  );
};

export default Visualizations;
