import React, { useEffect, useCallback } from 'react';
import Header from '../ContentHeader';
import dayjs from 'dayjs';
import CountUp from 'react-countup';

import styles from '../../index.module.scss';

interface Props {
  power: number;
  todayPower: number;
  monthPower: number;
  days: number;
  random: (min: number, max: number) => number;
  updatePower: (number: number) => void;
  updateTodayPower: (number: number) => void;
  updateMonthPower: (number: number) => void;
}
const Photovoltaic: React.FC<Props> = ({
  power,
  todayPower,
  monthPower,
  days,
  random,
  updatePower,
  updateTodayPower,
  updateMonthPower,
}) => {
  // 设置今日发电量
  const getTodayPower = useCallback(() => {
    // 获取当前时间
    const now = dayjs();

    // 设置开始时间和结束时间（7点和18点）
    const startTime = dayjs().hour(7).startOf('hour');
    const endTime = dayjs().hour(18).startOf('hour');

    const astartTime = dayjs().hour(18).startOf('hour');
    const aendTime = dayjs().hour(24).startOf('hour');

    if (now.isAfter(startTime) && now.isBefore(endTime)) {
      // 计算时间差（秒）
      const durationInSeconds = endTime.diff(startTime, 'second');

      // 计算每秒增加的发电量值
      const incrementPerSecond = 954.6 / durationInSeconds;

      // 计算当前时间相对于开始时间的时间差（秒）
      const elapsedTimeInSeconds = now.diff(startTime, 'second');

      // 计算当前时间对应的发电量值
      const currentPower = Math.min(incrementPerSecond * elapsedTimeInSeconds, 954.6);

      // 更新今日发电量的值
      updateTodayPower(currentPower);
    } else if (now.isAfter(astartTime) && now.isBefore(aendTime)) {
      updateTodayPower(964.6);
    } else {
      updateTodayPower(0);
    }
  }, [updateTodayPower]);

  // 设置本月发电量

  const getMonthPower = useCallback(() => {
    const power: number = Number((random(20, 25) * 381.84 * 2.5).toFixed(2));
    updateMonthPower(power);
  }, [updateMonthPower, random]);

  const calculatePowerAtTime = useCallback(() => {
    const now = dayjs();
    const startTime = dayjs().hour(7).startOf('hour');
    const endTime = dayjs().hour(18).startOf('hour');

    const aStartTime = dayjs().hour(10).startOf('hour');
    const aEndTime = dayjs().hour(11).startOf('hour');

    if (now.isAfter(startTime) && now.isBefore(endTime)) {
      return random(190, 210);
    } else if (now.isAfter(aStartTime) && now.isBefore(aEndTime)) {
      return random(200, 230);
    } else {
      updatePower(0);
    }
  }, [random, updatePower]);

  useEffect(() => {
    getMonthPower();
    updatePower(calculatePowerAtTime() as number);
    getTodayPower();
    const timer = setInterval(() => {
      updatePower(calculatePowerAtTime() as number);
      getTodayPower();
    }, 60000);
    return () => {
      clearInterval(timer);
    };
  }, [calculatePowerAtTime, getMonthPower, getTodayPower, updatePower]);

  return (
    <div className={styles.content_left_photovoltaic}>
      <Header title="光伏信息" info="光伏概览" url="https://pv-analysis.cloud.maxtropy.com/analysis/station"></Header>
      <div className={styles.runningDays}>{days}</div>
      <div className={styles.content_left_photovoltaic_centerline}></div>
      <div className={styles.content_left_photovoltaic_content}>
        <div className={styles.content_left_photovoltaic_pic}></div>
        <div className={styles.content_left_photovoltaic_security}>
          <div className={styles.content_left_photovoltaic_security_icon}></div>
          <div>安全运行</div>
          <div></div>
          <div>天</div>
        </div>
        <div>实时功率 (kW)</div>
        <div style={{ display: 'none' }}></div>
        <CountUp start={0} end={Number(power && power.toFixed(2))} decimals={2} />
        <div>今日发电量 (kWh)</div>
        <CountUp start={0} end={Number(todayPower && todayPower.toFixed(2))} decimals={2} />
        <div style={{ display: 'none' }}></div>
        <div>本月发电量 (kWh)</div>
        <CountUp start={0} end={Number(monthPower && monthPower.toFixed(2))} decimals={2} />
        <div></div>
      </div>
      <div className={styles.content_left_photovoltaic_bottom}></div>
    </div>
  );
};

export default Photovoltaic;
