import React, { useState, useEffect, useCallback } from 'react';
import Header from '../ContentHeader';
import dayjs from 'dayjs';
import CountUp from 'react-countup';

import styles from '../../index.module.scss';

interface Props {
  days: number;
  updateEnergyStorePower: (number: number) => void;
  updateEnergyStoreDischarge: (number: number) => void;
  energyStorePower: number;
  energyStoreDischarge: number;
}
const EnergyStore: React.FC<Props> = ({
  days,
  updateEnergyStoreDischarge,
  updateEnergyStorePower,
  energyStoreDischarge,
  energyStorePower,
}) => {
  // 本月放电量
  const [monthPower, setMonthPower] = useState<number>(0);
  // 本月充电量
  const [monthCharge, setMonthCharge] = useState<number>(0);

  // 充放电状态
  const [chargeStatus, setChargeStatus] = useState<boolean>(false);

  const day = dayjs().date();

  const getMonthPower = useCallback(() => {
    setMonthPower(Number((day * 430.41).toFixed(2)));
  }, [day]);

  const getMonthCharge = useCallback(() => {
    setMonthCharge(Number((day * 400.23).toFixed(2)));
  }, [day]);

  // 8-11点为放电状态,17-22点为放电状态.11-17点,22-24点,11-17点为充电状态,0-8点为充电状态
  const getChargeStatus = useCallback(() => {
    const hour = dayjs().hour();
    if (hour >= 8 && hour < 11) {
      setChargeStatus(false);
    } else if (hour >= 11 && hour < 17) {
      setChargeStatus(true);
    } else if (hour >= 17 && hour < 22) {
      setChargeStatus(false);
    } else if (hour >= 22 && hour < 24) {
      setChargeStatus(true);
    } else if (hour >= 0 && hour < 8) {
      setChargeStatus(true);
    }
  }, []);

  const getTodayPower = useCallback(() => {
    const now = dayjs();

    const startTime = dayjs().hour(8).startOf('hour');
    const endTime = dayjs().hour(11).startOf('hour');

    const astartTime = dayjs().hour(17).startOf('hour');
    const aendTime = dayjs().hour(22).startOf('hour');

    const stopTime = dayjs().hour(11).startOf('hour');
    const astopTime = dayjs().hour(17).startOf('hour');

    const finishTime = dayjs().hour(22).startOf('hour');
    const afinishTime = dayjs().hour(24).startOf('hour');

    if (now.isAfter(startTime) && now.isBefore(endTime)) {
      // 计算时间差（秒）
      const durationInSeconds = endTime.diff(startTime, 'second');
      // 计算每秒增加的发电量值
      const incrementPerSecond = 200 / durationInSeconds;
      // 计算当前时间相对于开始时间的时间差（秒）
      const elapsedTimeInSeconds = now.diff(startTime, 'second');
      // 计算当前时间对应的发电量值
      const currentPower = Math.min(incrementPerSecond * elapsedTimeInSeconds, 200);
      updateEnergyStoreDischarge(currentPower);
    } else if (now.isAfter(astartTime) && now.isBefore(aendTime)) {
      // 计算时间差（秒）
      const durationInSeconds = aendTime.diff(astartTime, 'second');
      // 计算每秒增加的发电量值
      const incrementPerSecond = 200 / durationInSeconds;
      // 计算当前时间相对于开始时间的时间差（秒）
      const elapsedTimeInSeconds = now.diff(astartTime, 'second');
      // 计算当前时间对应的发电量值
      const currentPower = Math.min(incrementPerSecond * elapsedTimeInSeconds, 400) + 200;
      updateEnergyStoreDischarge(currentPower);
    } else if (now.isAfter(stopTime) && now.isBefore(astopTime)) {
      updateEnergyStoreDischarge(200);
    } else if (now.isAfter(finishTime) && now.isBefore(afinishTime)) {
      updateEnergyStoreDischarge(400);
    }
  }, [updateEnergyStoreDischarge]);

  const getTodayCharge = useCallback(() => {
    const now = dayjs();

    const startTime = dayjs().hour(0).startOf('hour');
    const endTime = dayjs().hour(8).startOf('hour');

    const astartTime = dayjs().hour(11).startOf('hour');
    const aendTime = dayjs().hour(17).startOf('hour');

    const stopTime = dayjs().hour(8).startOf('hour');
    const astopTime = dayjs().hour(11).startOf('hour');

    const finishTime = dayjs().hour(17).startOf('hour');
    const afinishTime = dayjs().hour(24).startOf('hour');

    if (now.isAfter(startTime) && now.isBefore(endTime)) {
      const durationInSeconds = endTime.diff(startTime, 'second');
      // 计算每秒增加的发电量值
      const incrementPerSecond = 215 / durationInSeconds;
      // 计算当前时间相对于开始时间的时间差（秒）
      const elapsedTimeInSeconds = now.diff(startTime, 'second');
      // 计算当前时间对应的发电量值
      const currentPower = Math.min(incrementPerSecond * elapsedTimeInSeconds, 215);
      updateEnergyStorePower(currentPower);
    } else if (now.isAfter(astartTime) && now.isBefore(aendTime)) {
      const durationInSeconds = aendTime.diff(astartTime, 'second');
      // 计算每秒增加的发电量值
      const incrementPerSecond = 215 / durationInSeconds;
      // 计算当前时间相对于开始时间的时间差（秒）
      const elapsedTimeInSeconds = now.diff(astartTime, 'second');
      // 计算当前时间对应的发电量值
      const currentPower = Math.min(incrementPerSecond * elapsedTimeInSeconds, 430) + 215;
      updateEnergyStorePower(currentPower);
    } else if (now.isAfter(stopTime) && now.isBefore(astopTime)) {
      updateEnergyStorePower(215);
    } else if (now.isAfter(finishTime) && now.isBefore(afinishTime)) {
      updateEnergyStorePower(430);
    }
  }, [updateEnergyStorePower]);

  useEffect(() => {
    getMonthPower();
    getMonthCharge();
    getChargeStatus();
    getTodayPower();
    getTodayCharge();

    const timer = setInterval(() => {
      getTodayPower();
      getTodayCharge();
    }, 60000);
    return () => {
      clearInterval(timer);
    };
  }, [getMonthPower, getMonthCharge, getChargeStatus, getTodayPower, getTodayCharge]);

  return (
    <div className={styles.content_left_energy}>
      <Header title="储能信息" info="储能概览" url="https://bss-analysis.cloud.maxtropy.com/#/energy/welcome"></Header>
      <div className={styles.content_left_energy_content}>
        <div className={styles.content_left_energy_content_pic}></div>
        <div className={styles.content_left_energy_content_pic_cover}></div>
        <div className={styles.content_left_energy_content_status}>
          <div>工作状态</div>
          <div></div>
          {chargeStatus ? <ul></ul> : <p></p>}

          <div>{chargeStatus ? '充电' : '放电'}</div>
          <div>安全运行</div>
          <div>{days}</div>
          <div>天</div>
        </div>

        <div className={styles.content_left_energy_daily}>
          <div>今日充电量 (kWh)</div>
          <CountUp start={0} end={Number(energyStorePower.toFixed(2))} decimals={2} />
          <div style={{ display: 'none' }}></div>
          <div>今日放电量 (kWh)</div>
          <CountUp start={0} end={Number(energyStoreDischarge.toFixed(2))} decimals={2} />
          <div style={{ display: 'none' }}></div>
        </div>

        <div className={styles.content_left_energy_monthly}>
          <div>本月充电量 (kWh)</div>
          <div>{monthPower}</div>
          <div>本月放电量 (kWh)</div>
          <div>{monthCharge}</div>
        </div>
      </div>
      <div className={styles.content_left_energy_bottom}></div>
    </div>
  );
};

export default EnergyStore;
