import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, EllipsisSpan, usePaging, Paging } from '@maxtropy/components';
import { Col, Form, Input, message, Modal, Row, Select, Space, Table } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useMemo, useRef, useState } from 'react';
import { useRequest } from 'ahooks';
import { numberToString } from 'ytt-prompt';
import styles from '../index.module.scss';
import { apiStationDeviceListGet, StationDeviceListGetResponse } from '../../../ytt/apis/station/deviceList';
import { apiStationBindingDevicePost } from '../../../ytt/apis/station/bindingDevice';
import { Level } from '../DeviceInject';

interface Iprops {
  level?: Level;
  data?: any;
  cancel?: () => void;
  confirm?: () => void;
}
type TableRow = Exclude<StationDeviceListGetResponse['list'], undefined>[number];
const BindModalOp = ({ level, data, cancel, confirm }: Iprops) => {
  const [form] = useForm();
  const pagingInfo = usePaging();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [searchName, setSearchName] = useState<string>();
  const currentDeviceId = useRef<string>();
  const [messageApi, messageContextHolder] = message.useMessage();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const confirmBtn = () => {
    if (!currentDeviceId.current) {
      messageApi.error('请选择设备');
      return;
    }
    setConfirmLoading(true);
    apiStationBindingDevicePost({
      deviceId: currentDeviceId.current,
      level: level as string,
      stationID: data.station.stationID,
      equipmentID: data.zhuang.equipmentID,
      connectorID: data.gun.connectorID,
    })
      .then(_ => {
        confirm?.();
      })
      .finally(() => setConfirmLoading(false));
  };
  const buildColumns = [
    {
      title: '设备名称',
      dataIndex: 'deviceName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '所属组织',
      dataIndex: 'customerName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '所属类目',
      dataIndex: 'deviceType',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
  ];
  const { data: pageList, loading } = useRequest(
    () => {
      return apiStationDeviceListGet({
        deviceName: searchName,
        level: data.level,
        page: numberToString(pageOffset),
        size: numberToString(pageSize),
      }).then(res => {
        setTotalCount(res.total!);
        return res.list;
      });
    },
    {
      refreshDeps: [searchName, pageOffset, pageSize, data],
    }
  );
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: TableRow[]) => {
      currentDeviceId.current = selectedRowKeys[0] as string;
    },
    getCheckboxProps: (record: TableRow) => ({
      disabled: record.bind,
      name: record.deviceName,
    }),
  };
  return (
    <>
      <Modal title={`绑定设备`} open width={700} footer={null} onCancel={() => cancel?.()}>
        <div className={styles.form_content}>
          <Form form={form}>
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item label="设备名称" name="deviceName">
                  <Input></Input>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={() => {
                      let { deviceName } = form.getFieldsValue();
                      setSearchName(deviceName);
                    }}
                  >
                    查询
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <div style={{ paddingBottom: 20 }}>
            <Table
              rowKey="deviceId"
              loading={loading}
              columns={buildColumns}
              scroll={{ y: 400 }}
              rowSelection={{
                type: 'radio',
                ...rowSelection,
              }}
              dataSource={pageList}
              pagination={false}
            />
            <Paging pagingInfo={pagingInfo} />
          </div>
          <Row justify="end">
            <Space size={10}>
              <Button onClick={() => cancel?.()}>取消</Button>
              <Button type="primary" onClick={confirmBtn} loading={confirmLoading}>
                确定
              </Button>
            </Space>
          </Row>
        </div>
      </Modal>
      {messageContextHolder}
    </>
  );
};

export default BindModalOp;
