import React, { useState, useEffect, useCallback } from 'react';
import Header from '../ContentHeader';
import styles from '../../index.module.scss';

interface Props {
  todayPower: number;
  monthPower: number;
  random: (min: number, max: number) => number;
}

const calculateCo2Reduction = (power: number) => {
  return power * 0.997 * 0.001;
};

const GreenEnergy: React.FC<Props> = React.memo(({ todayPower, monthPower, random }) => {
  const [todayCo2, setTodayCo2] = useState<number>(0);
  const [monthCo2, setMonthCo2] = useState<number>(0);
  const [lastMonthCo2, setLastMonthCo2] = useState<number>(0);

  const calculateTodayCo2 = useCallback(() => {
    const power = calculateCo2Reduction(todayPower);
    setTodayCo2(power);
  }, [todayPower]);

  const calculateMonthCo2 = useCallback(() => {
    const power = calculateCo2Reduction(monthPower);
    setMonthCo2(power);
  }, [monthPower]);

  const calculateLastMonthCo2 = useCallback(() => {
    // const lastMonthPower = random(20, 25) * 381.84 * 2.5;
    // const power = calculateCo2Reduction(lastMonthPower);
    setLastMonthCo2(23.79);
  }, []);

  useEffect(() => {
    calculateTodayCo2();
    calculateMonthCo2();
    calculateLastMonthCo2();
  }, [calculateTodayCo2, calculateMonthCo2, calculateLastMonthCo2]);

  return (
    <div className={styles.content_right_greenenergy}>
      <Header title="绿色能源" />
      <div className={styles.content_right_greenenergy_content}>
        <div>CO2减排量</div>

        <div className={styles.content_right_greenenergy_content_leftBubble}>
          <div>{todayCo2.toFixed(2)}</div>
          <div>今日 (t)</div>
        </div>

        <div className={styles.content_right_greenenergy_content_centerBubble}>
          <div>{monthCo2.toFixed(2)}</div>
          <div>本月 (t)</div>
        </div>

        <div className={styles.content_right_greenenergy_content_rightBubble}>
          <div>{lastMonthCo2.toFixed(2)}</div>
          <div>上月 (t)</div>
        </div>
      </div>
      <div className={styles.content_right_greenenergy_bottomLine} />
    </div>
  );
});

export default GreenEnergy;
