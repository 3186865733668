import { FC, MouseEventHandler } from 'react';

import styles from './index.module.scss';

interface Props {
  title: string;
  info?: string;
  url?: string;
}

const Header: FC<Props> = ({ title, info, url }) => {
  const redirectToPage: MouseEventHandler<HTMLDivElement> = event => {
    if (url) {
      window.open(url)
    }
  };

  return (
    <>
      <div className={styles.header}>
        <p>{title}</p>
        {info && <div onClick={redirectToPage}>{info}</div>}
      </div>
    </>
  );
};

export default Header;
