import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';

import styles from '../../index.module.scss';

interface Props {}

const VisualizationsHeader: React.FC<Props> = () => {
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<string>(dayjs().locale('zh-cn').format('YYYY-MM-DD HH:mm:ss dddd'));

  const toggleFullscreen = () => {
    if (!isFullscreen) {
      enterFullscreen();
    } else {
      exitFullscreen();
    }
  };

  const enterFullscreen = () => {
    const element = document.querySelector('#screen_wrapper') as HTMLElement;
    if (element.requestFullscreen) {
      element.requestFullscreen();
    }
    setIsFullscreen(true);
  };

  const exitFullscreen = () => {
    const document = window.document as any;

    if (document.exitFullscreen) {
      document.exitFullscreen();
    }

    setIsFullscreen(false);
  };

  useEffect(() => {
    let currentTimer: NodeJS.Timeout;

    const updateCurrentTime = () => {
      setCurrentTime(dayjs().locale('zh-cn').format('YYYY-MM-DD HH:mm:ss dddd'));
    };

    currentTimer = setInterval(updateCurrentTime, 1000);

    return () => {
      clearInterval(currentTimer);
      if (isFullscreen) {
        exitFullscreen();
      }
    };
  }, [isFullscreen]);

  return (
    <header className={styles.header}>
      <div className={styles.fullscreen} onClick={toggleFullscreen}>
        {isFullscreen ? (
          <div className={styles.quitfullScreenIcon}></div>
        ) : (
          <div className={styles.fullScreenIcon}></div>
        )}
        <div>{isFullscreen ? '退出全屏' : '全屏'}</div>
      </div>
      <div className={styles.header_left}>
        <span className={styles.header_left_address}>地址</span>
        <span className={styles.header_left_location}>无锡经开区智能网联汽车生态产业园</span>
      </div>

      <div className={styles.header_title}>车联天下光储充一体化管理平台</div>

      <div className={styles.header_right}>
        <span className={styles.header_right_weather}>天气 多云</span>
        <span className={styles.header_right_time}>{currentTime}</span>
      </div>
    </header>
  );
};

export default VisualizationsHeader;
