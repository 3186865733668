import { Wrapper, Filter, usePaging, Paging, EllipsisSpan, useUpdate } from '@maxtropy/components';
import { Col, Form, Input, Select, Space, Table, Button, Modal } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useMemo, useState } from 'react';
import BindModalOp from './BindModalOp';
import styles from './index.module.scss';
import { useRequest } from 'ahooks';
import dayjs from 'dayjs';
import { apiStationListGet } from '../../ytt/apis/station/list';
import { apiStationUnbindingDevicePut } from '../../ytt/apis/station/unbindingDevice';
const routes = [{ name: '系统配置' }, { name: '设备接入' }, { name: '三方充电站接入' }];
const columns = [
  {
    title: '充电站',
    dataIndex: 'station',
    ellipsis: { showTitle: true },
    render: (v: any) => <EllipsisSpan value={v.stationName} />,
  },
  {
    title: '充电桩',
    dataIndex: 'zhuang',
    ellipsis: { showTitle: true },
    render: (v: any) => <EllipsisSpan value={v.equipmentName} />,
  },

  {
    title: '充电枪',
    dataIndex: 'gun',
    ellipsis: { showTitle: true },
    render: (v: any) => <EllipsisSpan value={v.connectorName} />,
  },
  {
    title: '设备名称',
    dataIndex: 'deviceName',
    ellipsis: { showTitle: true },
    render: (v: string, record: any) => <EllipsisSpan value={v} />,
  },
  {
    title: '绑定时间',
    dataIndex: 'bindTime',
    ellipsis: { showTitle: true },
    render: (v: any) => <EllipsisSpan value={v && dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
  {
    title: '绑定人',
    dataIndex: 'bindUserName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

interface SearchParams {
  bindState?: boolean;
  equipmentID?: string;
  stationID?: string;
}
export type Level = '1' | '2' | '3';
const DeviceInject = () => {
  const [form] = useForm();
  const [bindModalVisible, setBindModalVisible] = useState(false);
  const [currentStationID, setCurrentStationID] = useState<string>();
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [currentLevel, setCurrentLevel] = useState<Level>('1');
  const [currentRow, setCurrentRow] = useState<any>();
  const [update, setUpdate] = useUpdate();
  const [modalApi, modalContextHolder] = Modal.useModal();
  const buildColumns = [
    ...columns,
    {
      title: '操作',
      name: 'bindState',
      render: (v: boolean, record: any) => {
        let status: any = undefined;
        let level: Level = '1';
        if (typeof record.gun.bindState === 'boolean') {
          status = record.gun.bindState;
          level = '3';
        } else {
          if (typeof record.zhuang.bindState === 'boolean') {
            status = record.zhuang.bindState;
            level = '2';
          }
        }

        return (
          <>
            {typeof status === 'boolean' ? (
              <Space>
                <Button
                  type="link"
                  onClick={() => {
                    setCurrentRow(record);
                    bindingBtn(level, status, record.deviceId);
                  }}
                >
                  {status ? '解绑' : '绑定'}
                </Button>
              </Space>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
  ];
  const { data: originTableList, loading } = useRequest(apiStationListGet, {
    refreshDeps: [update],
  });
  const bindingBtn = (level: Level, status?: boolean, deviceId?: string) => {
    setCurrentLevel(level);
    if (typeof status === 'boolean' && !status) {
      setBindModalVisible(true);
      return;
    }

    // 解绑
    modalApi.confirm({
      title: null,
      icon: null,
      content: <div>您是否解绑此设备?</div>,
      onOk: () => {
        apiStationUnbindingDevicePut({
          deviceId: deviceId!,
        }).then(_ => {
          setUpdate();
        });
      },
    });
  };
  const pageList = useMemo(() => {
    const arr: any[] = [];
    if (originTableList) {
      originTableList.forEach(item1 => {
        // 充电站
        arr.push({ station: item1, zhuang: {}, gun: {}, ...item1 });
        // 充电桩
        item1.equipmentInfos?.forEach(item2 => {
          arr.push({ zhuang: item2, station: item1, gun: {}, ...item2 });
          // 充电枪
          item2?.connectorInfos?.forEach(item3 => {
            arr.push({
              gun: item3,
              station: item1,
              zhuang: item2,
              ...item3,
            });
          });
        });
      });
    }
    return arr;
  }, [originTableList]);
  // 所有充电站下拉列表
  const stationOptions = useMemo(() => {
    let station = pageList.map(item => {
      return { value: item.station.stationID, label: item.station.stationName };
    });
    let ids = station.map(item => item.value);
    // 去重
    let uniqueIds = [...new Set(ids)];
    return uniqueIds.map(id => {
      return {
        value: id,
        label: station.find(i => i.value === id)?.label,
      };
    });
  }, [pageList]);
  // 所有充电桩下拉列表
  const equipmentOptions = useMemo(() => {
    let equipment = pageList.map(item => {
      return { value: item.zhuang.equipmentID, label: item.zhuang.equipmentName, stationID: item.station.stationID };
    });
    let ids = equipment.map(item => item.value).filter(Boolean);
    // 去重
    let uniqueIds = [...new Set(ids)];
    return uniqueIds.map(id => {
      let info = equipment.find(i => i.value === id);
      return {
        value: id,
        label: info?.label,
        stationID: info?.stationID,
      };
    });
  }, [pageList]);
  // 级联关系
  const currentEquipmentOptions = useMemo(() => {
    if (currentStationID) {
      return equipmentOptions.filter(item => item.stationID === currentStationID);
    }
    return equipmentOptions;
  }, [equipmentOptions, currentStationID]);
  const filterPageList = useMemo(() => {
    if (searchParams) {
      let temp1 = pageList.filter(item => {
        if (searchParams.stationID) {
          return item.station.stationID === searchParams.stationID;
        }
        return item;
      });
      let temp2 = temp1.filter(item => {
        if (searchParams.equipmentID) {
          return item.zhuang.equipmentID === searchParams.equipmentID;
        }
        return item;
      });
      let temp3 = temp2.filter(item => {
        if (typeof searchParams.bindState === 'boolean') {
          return item.bindState === searchParams.bindState;
        }
        return item;
      });

      return temp3;
    }
    return pageList;
  }, [pageList, searchParams]);
  const filters = (
    <div className={styles.form_box}>
      <Filter
        form={form}
        onFinish={v => {
          console.log(v);
          setSearchParams(v as SearchParams);
        }}
        onReset={() => {
          setSearchParams(undefined);
          let dom = document.querySelector('.ant-table-body') as HTMLDivElement;
          dom.scrollTop = 0;
        }}
      >
        <Col span={6}>
          <Form.Item name="stationID" label="充电站">
            <Select
              placeholder="请选择充电站"
              options={stationOptions}
              optionFilterProp="label"
              showSearch
              onChange={(v: string) => {
                setCurrentStationID(v);
                form.setFieldsValue({
                  equipmentID: undefined,
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="equipmentID" label="充电桩">
            <Select placeholder="请选择充电桩" optionFilterProp="label" showSearch options={currentEquipmentOptions} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="bindState" label="绑定状态">
            <Select placeholder="请选择绑定状态">
              <Select.Option value={''}>全部</Select.Option>
              <Select.Option value={true}>是</Select.Option>
              <Select.Option value={false}>否</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Filter>
    </div>
  );
  return (
    <>
      <Wrapper className={styles.wrapperStyles} routes={routes} filters={filters}>
        <Table
          scroll={{ y: 600 }}
          loading={loading}
          rowKey="id"
          columns={buildColumns}
          dataSource={filterPageList}
          pagination={false}
        />
      </Wrapper>
      {bindModalVisible && (
        <BindModalOp
          data={currentRow}
          level={currentLevel}
          confirm={() => {
            setBindModalVisible(false);
            setUpdate();
          }}
          cancel={() => setBindModalVisible(false)}
        />
      )}
      {modalContextHolder}
    </>
  );
};

export default DeviceInject;
