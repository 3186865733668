import React, { useEffect, useMemo } from 'react';
import ReactEcharts from 'echarts-for-react';
import dayjs from 'dayjs';

interface Props {
  todayPower: number;
  random(min: number, max: number): number;
  energyStorePower: number;
  energyStoreDischarge: number;
}

const EchartsDemo: React.FC<Props> = ({ todayPower, random, energyStoreDischarge, energyStorePower }) => {
  useEffect(() => {}, []);

  const randomData1 = useMemo(() => {
    return Array.from({ length: 6 }, () => random(100, 954).toFixed(2));
  }, [random]);

  const randomData2 = useMemo(() => {
    return Array.from({ length: 6 }, () => random(100, 954).toFixed(2));
  }, [random]);

  const randomData3 = useMemo(() => {
    return Array.from({ length: 6 }, () => random(400, 430).toFixed(2));
  }, [random]);

  const randomData4 = useMemo(() => {
    return Array.from({ length: 6 }, () => random(400, 430).toFixed(2));
  }, [random]);

  const dates = [];
  const today = dayjs(); // 获取当前日期

  for (let i = 6; i >= 0; i--) {
    const date = today.subtract(i, 'day').format('MM-DD');
    dates.push(date);
  }

  const getOption = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      confine: true,
      backgroundColor: 'rgba(0,0,0)',
      borderColor: 'rgba(0,0,0)',
      padding: [8, 12],
      textStyle: {
        fontFamily: 'PingFangSC-Medium, PingFang SC',
        fontSize: 12,
        color: '#fff',
        lineHeight: 17,
      },
      formatter: function (params: any) {
        const date = dayjs(params[0].axisValue).format('MM-DD');
        let content = `<div style="background-color:#000" width:220px;height:100%;;> <p style="height:5px;float:left;height:8px;">${date}</p>`;
        for (let i = 0; i < params.length; i++) {
          const { seriesName, color, value } = params[i];
          content += '<div style="width:100%;display: flex; justify-content: space-between;align-item:center;">';
          content += `<div style=''>`;
          content +=
            '<span style="display: inline-block;width: 8px; height: 8px; margin-right: 4px;align-self:flex-start;background-color: ' +
            color +
            ';"></span>';
          content +=
            '<span style="display: inline-block;margin-right: 8px; color: #fff;align-self:flex-start;">' +
            seriesName +
            '</span>';
          content += '</div>';

          content += '<span style="color: #fff;display: inline-block;float:right">' + value + ' kWh</span>';
          content += '</div>';
        }
        content += '</div>';
        return content;
      },
      // extraCssText:
      //   'padding:8px,12px;width: 220px; height: 113px; display: flex; flex-direction: column; justify-content: space-around; align-items: center;',
      extraCssText: 'width: 220px; height: 113px; ',
    },
    grid: {
      top: '80px',
      left: '21px',
      right: '16px',
      bottom: '24px',
      containLabel: true,
    },
    legend: {
      data: ['光伏站发电量', '充电站充电量', '储能站充电量', '储能站放电量'],
      width: 250,
      orient: 'horizontal',
      padding: [0, 0, 0, 0],
      top: 19,
      right: 16,
      textStyle: {
        color: '#fff',
        fontFamily: 'PingFangSC-Regular, PingFang SC',
        fontSize: 12,
        rich: {
          a: {
            verticalAlign: 'middle',
          },
        },
        lineHeight: 12,
        padding: [0, -2, -4, 0],
      },
      itemWidth: 8,
      itemHeight: 8,
      itemGap: 16,
    },
    xAxis: {
      type: 'category',
      data: dates,
      axisLine: {
        lineStyle: {
          color: 'rgba(255,255,255,0.3)',
          type: 'solid',
          width: 1,
        },
      },
      axisLabel: {
        fontFamily: 'PingFangSC-Regular, PingFang SC',
        fontSize: 12,
        color: '#fff',
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      type: 'value',
      min: 0,
      max: 1000,
      axisLine: {
        lineStyle: {
          color: '#999',
        },
        show: false,
      },
      splitLine: {
        show: false,
      },
      axisLabel: {
        formatter: (value: number) => {
          if (value === 0 || value === 1000) {
            return value.toString();
          }
          return '';
        },
        fontFamily: 'PingFangSC-Regular, PingFang SC',
        fontSize: 12,
        color: '#fff',
        rich: {
          custom: {
            color: '#fff',
            fontFamily: 'PingFangSC-Regular, PingFang SC',
            fontSize: 12,
            padding: [0, 0, 0, 0],
          },
        },
      },
    },
    series: [
      {
        name: '光伏站发电量',
        type: 'bar',
        barWidth: 8,
        barCategoryGap: '2px',
        data: [...randomData1, todayPower.toFixed(2)],
        itemStyle: {
          color: '#28E159',
        },
      },
      {
        name: '充电站充电量',
        type: 'bar',
        barWidth: 8,
        barCategoryGap: '2px',
        data: [...randomData2, 136.88],
        itemStyle: {
          color: '#7BFFF2',
        },
      },
      {
        name: '储能站充电量',
        type: 'bar',
        barWidth: 8,
        barCategoryGap: '2px',
        data: [...randomData3, energyStorePower.toFixed(2)],
        itemStyle: {
          color: '#44B6FF',
        },
      },
      {
        name: '储能站放电量',
        type: 'bar',
        barWidth: 8,
        barCategoryGap: '2px',
        data: [...randomData4, energyStoreDischarge.toFixed(2)],
        itemStyle: {
          color: '#FFDF7B ',
        },
      },
    ],
    graphic: [
      {
        type: 'group',
        left: '23px',
        top: '52px',
        children: [
          {
            type: 'text',
            z: 100,
            style: {
              text: 'kWh',
              fill: '#fff',
              fontSize: 12,
              textAlign: 'center',
            },
          },
        ],
      },
    ],
  };

  return (
    <div style={{ width: 400, height: 302 }}>
      <ReactEcharts option={getOption} />
    </div>
  );
};

export default EchartsDemo;
