import React, { useEffect } from 'react';

import Header from '../ContentHeader';
import CountUp from 'react-countup';

import styles from '../../index.module.scss';
interface Props {
  days: number;
  random: (min: number, max: number) => number;
}
const ChargeInfo: React.FC<Props> = ({ days, random }) => {
  // 实时功率
  const [power, setPower] = React.useState<number>(0);
  useEffect(() => {
    setPower(102);
    const timer = setInterval(() => {
      setPower(random(99, 120));
    }, 60000);
    return () => {
      clearInterval(timer);
    };
  }, [random]);
  return (
    <div className={styles.content_right_charge}>
      <Header
        title="充电站信息"
        info="充电站概览"
        url="https://ev-charging-analysis.cloud.maxtropy.com/csm/operationBoard?isvAppToken=5d7cd885-b09e-4ccf-bdd0-d93d0ef9923e"
      ></Header>
      <div className={styles.content_right_charge_content}>
        <div className={styles.content_right_charge_content_pic}></div>

        <div className={styles.content_right_charge_content_operation}>
          <div></div>
          <div>安全运行</div>
          <div>{days}</div>
          <div>天</div>
        </div>

        <div className={styles.content_right_charge_content_pile}>
          <div></div>
          <div>充电桩数</div>
          <div>11</div>
          <div>台</div>
        </div>

        <div>实时功率 (kW)</div>
        <CountUp start={0} end={power} decimals={2} />
        <div style={{ display: 'none' }}></div>
        <div>今日充电量 (kWh)</div>
        <div>136.88</div>
        <div>本月充电量 (kWh)</div>
        <div>1623.66</div>
      </div>

      <div className={styles.content_right_charge_middleLine}></div>
      <div className={styles.content_right_charge_bottomLine}></div>
    </div>
  );
};

export default ChargeInfo;
