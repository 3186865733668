import React from 'react';

import './App.scss';
import { SwitchStaff, HeadNavigation, SiderMenu, SystemContent, UserContent } from '@maxtropy/rc-components';
import { MenuItem } from '@maxtropy/rc-components/es/HeadNavigation';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';

import { Divider, Space } from 'antd';
import DeviceInject from './pages/System/DeviceInject';
import Visualizations from './pages/Visualizations';
import { CustomApp, useThemeContext } from '@maxtropy/components';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
function App() {
  const theme = useThemeContext();
  const devMenu: MenuItem = {
    currentId: 'dev',
    name: '开发支持',
    level: 1,
    openType: 0,
    parentId: '',
    transitPagePath: null,
    rootId: 'dev',
    weighting: 1000,
    children: [
      {
        currentId: 'dev/1',
        name: '前端组件库',
        level: 2,
        openType: 0,
        parentId: 'dev/1',
        transitPagePath: null,
        weighting: 1,
        rootId: 'dev',
        children: [
          {
            currentId: 'dev/1/1',
            rootId: 'dev',
            level: 3,
            name: '第三方设备接入',
            openType: 0,
            parentId: 'dev/1',
            transitPagePath: '/system/setting/inject',
            weighting: 1,
          },
        ],
      },
    ],
  };
  return (
    <div className="App">
      <ConfigProvider locale={zhCN} theme={theme} space={{ size: 3 }}>
        <CustomApp style={{ height: '100%' }}>
          <Router>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                backgroundColor: '#1b1b1b',
                padding: '0 20px',
              }}
            >
              <SystemContent />
              <HeadNavigation overlayStyle={{ flex: '1 1', minWidth: 0 }} devMenu={devMenu}></HeadNavigation>
              <Space split={<Divider type="vertical" />}>
                <SwitchStaff />
                <UserContent />
              </Space>
            </div>
            <div style={{ display: 'flex', height: 'calc(100vh - 50px)' }}>
              <SiderMenu devMenu={devMenu} overlayStyle={{ height: '100%' }}></SiderMenu>
              <div style={{ flex: 1 }}>
                <div className="router-box-outer">
                  <div className="router-box-inner">
                    <Routes>
                      <Route path="/system/setting/inject" element={<DeviceInject />} />
                      <Route path="/system/setting/visualizations" element={<Visualizations />} />
                    </Routes>
                  </div>
                </div>
              </div>
            </div>
          </Router>
        </CustomApp>
      </ConfigProvider>
    </div>
  );
}

export default App;
